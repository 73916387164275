export enum RoleEnum {
  User = 'User',
  Admin = 'Admin',
  Staff = 'Staff',
}

export enum RoleOrganizationEnum {
  User = 'User',
  Owner = 'Owner',
}

export enum PaymentProvidersEnum {
  SISPShopvinti4 = 'SISPShopvinti4',
  BankTransfer = 'BankTransfer',
  Verifone = 'Verifone',
  LunuPay = 'Lunu Crypto Pay',
  BinancePay = 'Binance Pay',
}

export enum BillingModel {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
}

export enum SocketEnum {
  fanout = 'fanout',
  amqDirect = 'amq.direct',
  default = 'default',
  subscribeToUserNotification = 'USER-NOTIFICATION',
  subscribeToNamespaceInfo = 'USER-NAMESPACEINFO',
}

export enum PaymentOrderStatusEnum {
  WaitingForPayment = 'WaitingForPayment',
  Payed = 'Payed',
}

export enum PlanEnum {
  Trial = 'Trial',
  Standard = 'Standard',
  Enterprise = 'Enterprise',
}
